import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'

const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
`

const Title = styled.h2`
    text-transform: uppercase;
` 

const Condition = styled.p`
    font-size: 12pt;
    font-weight: 300;
`

export default function PowerPackExpansionConditions() {

    const { appState } = useContext(AppContext);

    return (
        <Container>
            <Condition>{appState.i18n.aboutPlans.powerpack_expansion.expansion_possible_text}</Condition>
        </Container>
    )
}
