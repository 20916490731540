import { Link } from 'gatsby';
import React from 'react'
import { useContext } from 'react';
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext';
import DefaultButton from '../common/Button';
import PowerPackExpansionTile from './PowerPackExpansionTile';

const PowerPacksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    
    @media (max-width: 425px) {
        flex-direction: column;
        align-items: center;
    }
`
const Container = styled.div`
    margin: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default function PowerPacksExpansionCarousel() {

    const { appState } = useContext(AppContext);

    return (
        <Container>
            <PowerPacksContainer>
           
            
                <PowerPackExpansionTile
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.monophasic} 
                     pack={"20"} 
                     panelsPower={"7,5"} 
                     availableInverters={"3.7 kW, 5 kW"} 
                     availableBatteries={"3, 6, 9, 12 kWh"} 
 
                    />
                <PowerPackExpansionTile
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.monophasic + "/" + appState.i18n.powerpacks.triphasic} 
                     pack={"40"} 
                     panelsPower={"15"} 
                     availableInverters={"8kW, 10 kW" + " " + appState.i18n.powerpacks.triphasic} 
                     availableBatteries={"6, 9, 12 kWh"}   
                    />
                <PowerPackExpansionTile
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.triphasic} 
                     pack={"60"} 
                     panelsPower={"18"} 
                     availableInverters={"10 kW, 12 kW, 15 kW" + " " + appState.i18n.powerpacks.triphasic } 
                     availableBatteries={"6, 9, 12 kWh"}   
                    />
                
            </PowerPacksContainer>
            <Link to="/powerpacks-carregamento-eletrico-casa">
                <DefaultButton label={appState.i18n.powerpacks.purchase.toUpperCase()} />
            </Link>
        </Container>
    )
}


