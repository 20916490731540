import { Link } from 'gatsby';
import React from 'react'
import { useContext } from 'react';
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext';
import DefaultButton from '../common/Button';
import PowerPackTileLean from './PowerPackTileLean';

const PowerPacksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    
    @media (max-width: 425px) {
        flex-direction: column;
        align-items: center;
    }
`
const Container = styled.div`
    margin: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default function PowerPacksCarousel() {

    const { appState } = useContext(AppContext);

    return (
        <Container>
            <PowerPacksContainer>
           
            
                <PowerPackTileLean
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.monophasic} 
                     pack={"20"} 
                     dailyProduction={"8"} 
                     power={"3,7"} 
                     accum={"3"} 
                     charger={appState.i18n.powerpacks.simpleCharger}
                     includedKms={"7300"} 
                     roi={"8,56%"} 
                     implArea={"10"} 
                     price={"€8.299"}
                    />
                <PowerPackTileLean
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.monophasic + "/" + appState.i18n.powerpacks.triphasic} 
                     pack={"40"} 
                     dailyProduction={"12"} 
                     power={"6"} 
                     accum={"6"} 
                     charger={appState.i18n.powerpacks.simpleCharger}
                     includedKms={"14600"} 
                     roi={"9,93%"} 
                     implArea={"14"} 
                     price={"€10.799"}
                    />
                <PowerPackTileLean
                     title={"PowerPack"}
                     subtitle={appState.i18n.powerpacks.triphasic} 
                     pack={"60"} 
                     dailyProduction={"20"} 
                     power={"8"} 
                     accum={"9"} 
                     charger={appState.i18n.powerpacks.simpleCharger}
                     includedKms={"22000"} 
                     roi={"11,6%"} 
                     implArea={"24"} 
                     price={"€14.899"}
                    />
                
            </PowerPacksContainer>
            <Link to="/powerpacks-carregamento-eletrico-casa">
                <DefaultButton label={appState.i18n.powerpacks.purchase.toUpperCase()} />
            </Link>
        </Container>
    )
}


