import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'

const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Title = styled.h2`
    text-transform: uppercase;
` 

const Condition = styled.p`
    font-size: 12pt;
    font-weight: 300;
`

export default function MobilityConditions() {

    const { appState } = useContext(AppContext);

    return (
        <Container>
            <Title>{appState.i18n.conditions.title}</Title>
            <Condition>{appState.i18n.conditions.mobility.noSigningCosts}</Condition>
            <Condition>{appState.i18n.conditions.mobility.noTerm}</Condition>
            <Condition>{appState.i18n.conditions.mobility.directDebit}</Condition>
            <Condition>{appState.i18n.conditions.mobility.digitalInvoice}</Condition>
        </Container>
    )
}
