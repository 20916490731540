import React, { useContext } from 'react'
import Layout from '../../../../components/layout'
import AboutPlanTower from '../../../../components/layout/AboutPlanTower'
import CardsDisplayer from '../../../../components/layout/CardsDisplayer'
import DotDivider from '../../../../components/layout/DotDivider'
import HeroContainer from '../../../../components/layout/HeroContainer'
import MobilityConditions from '../../../../components/layout/MobilityConditions'
import MobilityPricingTable from '../../../../components/layout/MobilityPricingTable'
import RegisterButton from '../../../../components/layout/RegisterButton'
import Footer from '../../../../components/layout/Footer'
import SimilarOffers from '../../../../components/layout/SimilarOffers'
import Offer from '../../../../components/layout/Offer'
import { AppContext } from '../../../../contexts/AppContext'
import PowerPacksCarousel from '../../../../components/layout/PowerPacksCarousel'
import SEO from '../../../../components/seo'
import PowerPackConditions from '../../../../components/layout/PowerPackConditions'
import PowerPacksExpansionCarousel from '../../../../components/layout/PowerPacksExpansionCarousel'
import PowerPackExpansionConditions from '../../../../components/layout/PowerPackExpansionConditions'
import styled from 'styled-components'


const powerpack_Cards = [{ key: "adaptable" }, { key: "completeSolution" }, { key: "guaranteedService" }]

const snippets = [{
    title: "whatIsIt_Title",
    text: "whatIsIt"
},
{
    title: "howItWorks_Title",
    text: "howItWorks"
},
{
    title: "circularEconomyTitle",
    text: "circularEconomy"
},]

const expansion_snippets = []


export default function MobilityPage() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.heroContainers.powerpacksPageTitle} />
            <HeroContainer title={appState.i18n.heroContainers.powerpacksPageTitle} subtitle={appState.i18n.heroContainers.powerpacksPageText} id="powerpack" />            
            <DotDivider />
            <PowerPacksCarousel />
            <PowerPackConditions />
            <AboutPlanTower page={"powerpack_expansion"} title={appState.i18n.aboutPlans.powerpack_expansion.expansion_possible_title} snippets={expansion_snippets}/>
            <PowerPacksExpansionCarousel />
            <PowerPackExpansionConditions />
            <DotDivider />
            <AboutPlanTower page={"powerpack"} snippets={snippets}/>
            <DotDivider />
            <CardsDisplayer cards={powerpack_Cards} />
            <DotDivider />
            <SimilarOffers title={appState.i18n.similarOffers.title}>
                {/* <Offer link={"/solutions/private/powerbox"} title={appState.i18n.similarOffers.powerboxesTitle} text={appState.i18n.similarOffers.powerboxesText}/> */}
                <Offer link={"/solutions/plans/mobility"} title={appState.i18n.similarOffers.mobilityPlanTitle} text={appState.i18n.similarOffers.mobilityPlanText}/>
                <Offer link={"/solutions/plans/residential"} title={appState.i18n.similarOffers.residentialPlanTitle} text={appState.i18n.similarOffers.residentialPlanText}/>
            </SimilarOffers>
            <Footer />
        </Layout>
    )
}
