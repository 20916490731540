import styled from 'styled-components'
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Button } from '@material-ui/core';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '../common/Tooltip';

const Tile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 20px 0 20px;
    width: 325px;
    @media (max-width: 425px) {
        margin: 50px auto 0 auto;
    }
`

const Container = styled.div`
    min-width: 325px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: white;
`

const TileHeader = styled.div`
    border-radius: 20px;
    padding: 30px;
    min-height: 200px;
`

const Subtitle = styled.h4`
    font-weight: 400 !important;
    margin: 0;
    font-size: 18pt;
`

const Title = styled.h2`
    margin: 0;
    font-size: 25pt;
`

const LabelPower = styled.span`
    color: ${props => props.pack === "20" ? "#29ABE2" : props.pack === "40" ? "#00a589" : props.pack === "60" ? "#2E3192" : "#29ABE2"} ;
`

const MainList = styled.div`
    margin-top: 20px;
`

const MainListItems = styled.h5`
    margin: 0;
    font-weight: 400;
    font-size: 12pt;
    padding: 0px 5px 0 5px;
`

const MainListItemsTitle = styled.h5`
    margin: 0;
    font-weight: 600;
    font-size: 12pt;
    padding: 10px 5px 0 5px;
`

const TileContent = styled.div`
    padding: 30px;
    background-color: ${props => props.pack === "20" ? "#29ABE2" : props.pack === "40" ? "#00a589" : props.pack === "60" ? "#2E3192" : "#29ABE2"} ;
    opacity: 0.8;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
`

const SecItemsCont = styled.div`
    padding: 15px 0;
    line-height: 17px;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`

const SecondaryListItems = styled.div`
    display: flex;
    color: white;
    font-size: 14pt;
    font-weight: 400;
`

const SecondaryItemDesc = styled.span`
    font-size: 10pt;
    color: white;
`

const HeaderSecondaryItemDesc = styled.span`
    font-size: 10pt;
    color: black;
    padding: 0 5px;
`

const PricesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PriceLabel = styled.span`
    font-size: 16pt;
    font-weight: 500;
`

const Price = styled.span`
    font-size: 20pt;
    font-weight: 700;
    margin: 10px 0 40px 0;
`

const BuyButton = styled(Button)`
` 

export default function PowerPackExpansionTile({ title, subtitle, pack, panelsPower, availableInverters, availableBatteries }) {

    const { appState } = useContext(AppContext)

   

    return (
        <Tile>
            <Container className="shadow">
                <TileHeader>
                    {/* <Subtitle>{appState.i18n.powerpacks.pack}</Subtitle> */}
                    <Title>{title} <LabelPower pack={pack}>{pack}</LabelPower></Title>
                    <Subtitle>{subtitle}</Subtitle>
                    <MainList>
                        <MainListItemsTitle>{appState.i18n.powerpacks.panels}</MainListItemsTitle>
                        <MainListItems>{appState.i18n.powerpacks.upTo} {panelsPower} kWp</MainListItems>

                        <MainListItemsTitle>{appState.i18n.powerpacks.availableInverters}</MainListItemsTitle>
                        <MainListItems>{availableInverters}</MainListItems>
                        
                        <MainListItemsTitle>{appState.i18n.powerpacks.availableBatteries}</MainListItemsTitle>
                        <MainListItems>{availableBatteries}</MainListItems>
                    </MainList>
                </TileHeader>
            </Container>
        </Tile>
    )
}
